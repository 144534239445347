import { appRoute } from './defaultValues';

const data = [
  {
    id: 'Users',
    icon: 'iconsminds-male-female',
    label: 'menu.users',
    to: `${appRoute}/users`,
  },
  {
    id: 'Posts',
    icon: 'iconsminds-big-data',
    label: 'menu.posts',
    to: `${appRoute}/posts`,
  },
  {
    id: 'Invitations',
    icon: 'iconsminds-mailbox-full',
    label: 'menu.invitations',
    to: `${appRoute}/invitations`,
  },
  {
    id: 'Talent',
    icon: 'iconsminds-box-close',
    label: 'menu.talents',
    to: `${appRoute}/talents`,
  },
  {
    id: 'Sub Talent',
    icon: 'iconsminds-box-with-folders',
    label: 'menu.subTalents',
    to: `${appRoute}/subTalents`,
  },
  {
    id: 'Reports',
    icon: 'iconsminds-danger',
    label: 'menu.report',
    to: `${appRoute}/report`,
  },
  {
    id: 'Newsletter',
    icon: 'iconsminds-mail-send',
    label: 'menu.newsletter',
    to: `${appRoute}/newsletter`,
  },
];
export default data;
