import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSaga from './users/saga';
import postSaga from './posts/saga';
import invitationSaga from './invitations/saga';
import talentSaga from './talent/saga';
import reportSaga from './reports/saga';
import newsletterSaga from './newsletter/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    userSaga(),
    postSaga(),
    invitationSaga(),
    talentSaga(),
    reportSaga(),
    newsletterSaga(),
  ]);
}
