/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import * as actionType from './actionType';
import * as actions from './actions';

function* fetchPostListing({ payload }) {
  const { page } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/posts/recentPosts?data=true&pageNo=${page}&limit=50`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.getPostListingSuccess(data));
    if (data?.length > 0) {
      yield put(actions.getPostListing(page + 1));
    }
  } else {
    const { message } = response.data;
    yield put(actions.getPostListingFailed(message));
  }
}

function* deactivatePost({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/posts/deactivate/${payload}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.inactivePostSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.inactivePostFailed(message));
  }
}

function* activatePost({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/posts/activate/${payload}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.activePostSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.activePostFailed(message));
  }
}

export function* watchPost() {
  yield takeEvery(actionType.GET_POST_LISTING, fetchPostListing);
  yield takeEvery(actionType.ACTIVE_POST, activatePost);
  yield takeEvery(actionType.INACTIVE_POST, deactivatePost);
}

export default function* rootSaga() {
  yield all([fork(watchPost)]);
}
