import * as actionType from './actionType';

export const getUserListing = () => ({
  type: actionType.GET_USER_LISTING,
});
export const getUserListingSuccess = (listing) => ({
  type: actionType.GET_USER_LISTING_SUCCESS,
  payload: listing,
});
export const getUserListingFailed = (message) => ({
  type: actionType.GET_USER_LISTING_FAILED,
  payload: { message },
});

export const deactivateUser = (userId) => ({
  type: actionType.DEACTIVATE_USER,
  payload: userId,
});
export const deactivateUserSuccess = () => ({
  type: actionType.DEACTIVATE_USER_SUCCESS,
});
export const deactivateUserFailed = (message) => ({
  type: actionType.DEACTIVATE_USER_FAILED,
  payload: { message },
});

export const createAmbassador = (userId) => ({
  type: actionType.CREATE_AMBASSADOR,
  payload: userId,
});
export const createAmbassadorSuccess = () => ({
  type: actionType.CREATE_AMBASSADOR_SUCCESS,
});
export const createAmbassadorFailed = (message) => ({
  type: actionType.CREATE_AMBASSADOR_FAILED,
  payload: { message },
});

export const removeAmbassador = (userId) => ({
  type: actionType.REMOVE_AMBASSADOR,
  payload: userId,
});
export const removeAmbassadorSuccess = () => ({
  type: actionType.REMOVE_AMBASSADOR_SUCCESS,
});
export const removeAmbassadorFailed = (message) => ({
  type: actionType.REMOVE_AMBASSADOR_FAILED,
  payload: { message },
});

export const activateUser = (userId) => ({
  type: actionType.ACTIVATE_USER,
  payload: userId,
});
export const activateUserSuccess = () => ({
  type: actionType.ACTIVATE_USER_SUCCESS,
});
export const activateUserFailed = (message) => ({
  type: actionType.ACTIVATE_USER_FAILED,
  payload: { message },
});
