import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import users from './users/reducer';
import posts from './posts/reducer';
import invitations from './invitations/reducer';
import talent from './talent/reducer';
import reports from './reports/reducer';
import newsletter from './newsletter/reducer';

import { LOGOUT_USER_SUCCESS } from './actions';

const appReducer = combineReducers({
  menu,
  settings,
  authUser,
  users,
  posts,
  invitations,
  talent,
  reports,
  newsletter,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === LOGOUT_USER_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
