export const GET_POST_LISTING = 'GET_POST_LISTING';
export const GET_POST_LISTING_SUCCESS = 'GET_POST_LISTING_SUCCESS';
export const GET_POST_LISTING_FAILED = 'GET_POST_LISTING_FAILED';

export const INACTIVE_POST = 'INACTIVE_POST';
export const INACTIVE_POST_SUCCESS = 'INACTIVE_POST_SUCCESS';
export const INACTIVE_POST_FAILED = 'INACTIVE_POST_FAILED';

export const ACTIVE_POST = 'ACTIVE_POST';
export const ACTIVE_POST_SUCCESS = 'ACTIVE_POST_SUCCESS';
export const ACTIVE_POST_FAILED = 'ACTIVE_POST_FAILED';
