import * as actionType from './actionType';

export const processNewsletter = (payload) => ({
  type: actionType.PROCESS_NEWSLETTER_EMAILS,
  payload,
});
export const processNewsletterSuccess = () => ({
  type: actionType.PROCESS_NEWSLETTER_EMAILS_SUCCESS,
});
export const processNewsletterFailed = (message) => ({
  type: actionType.PROCESS_NEWSLETTER_EMAILS_FAILED,
  payload: { message },
});
