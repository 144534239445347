export const GET_TALENT_LISTING = 'GET_TALENT_LISTING';
export const GET_TALENT_LISTING_SUCCESS = 'GET_TALENT_LISTING_SUCCESS';
export const GET_TALENT_LISTING_FAILED = 'GET_TALENT_LISTING_FAILED';

export const ADD_TALENT = 'ADD_TALENT';
export const ADD_TALENT_SUCCESS = 'ADD_TALENT_SUCCESS';
export const ADD_TALENT_FAILED = 'ADD_TALENT_FAILED';

export const UPDATE_TALENT = 'UPDATE_TALENT';
export const UPDATE_TALENT_SUCCESS = 'UPDATE_TALENT_SUCCESS';
export const UPDATE_TALENT_FAILED = 'UPDATE_TALENT_FAILED';

export const DELETE_TALENT = 'DELETE_TALENT';
export const DELETE_TALENT_SUCCESS = 'DELETE_TALENT_SUCCESS';
export const DELETE_TALENT_FAILED = 'DELETE_TALENT_FAILED';

export const GET_SUB_TALENT_LISTING = 'GET_SUB_TALENT_LISTING';
export const GET_SUB_TALENT_LISTING_SUCCESS = 'GET_SUB_TALENT_LISTING_SUCCESS';
export const GET_SUB_TALENT_LISTING_FAILED = 'GET_SUB_TALENT_LISTING_FAILED';

export const ADD_SUB_TALENT = 'ADD_SUB_TALENT';
export const ADD_SUB_TALENT_SUCCESS = 'ADD_SUB_TALENT_SUCCESS';
export const ADD_SUB_TALENT_FAILED = 'ADD_SUB_TALENT_FAILED';

export const UPDATE_SUB_TALENT = 'UPDATE_SUB_TALENT';
export const UPDATE_SUB_TALENT_SUCCESS = 'UPDATE_SUB_TALENT_SUCCESS';
export const UPDATE_SUB_TALENT_FAILED = 'UPDATE_SUB_TALENT_FAILED';

export const DELETE_SUB_TALENT = 'DELETE_SUB_TALENT';
export const DELETE_SUB_TALENT_SUCCESS = 'DELETE_SUB_TALENT_SUCCESS';
export const DELETE_SUB_TALENT_FAILED = 'DELETE_SUB_TALENT_FAILED';

export const EDITOR_MODAL_TOGGLE = 'EDITOR_MODAL_TOGGLE';
