import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import {
  GET_SUB_TALENT_LISTING,
  GET_TALENT_LISTING,
  UPDATE_SUB_TALENT,
  UPDATE_TALENT,
} from './actionType';
import {
  getTalentListingSuccess,
  getTalentListingFailed,
  getSubTalentListingSuccess,
  getSubTalentListingFailed,
  updateTalentSuccess,
  updateTalentFailed,
  getTalentListing,
  editorModalToggle,
  updateSubTalentSuccess,
  updateSubTalentFailed,
  getSubTalentListing,
} from './actions';

function* fetchTalentListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/category`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getTalentListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getTalentListingFailed(message));
  }
}

function* updateTalent({ payload }) {
  const { id, name, description } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/category/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: {
      name,
      description,
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(updateTalentSuccess(data));
    yield put(getTalentListing());
    yield put(editorModalToggle(false));
  } else {
    const { message } = response.data;
    yield put(updateTalentFailed(message));
  }
}

function* updateSubTalent({ payload }) {
  const { id, name, description, categoryId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/subCategory/update/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: {
      name,
      description,
      categoryId,
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(updateSubTalentSuccess(data));
    yield put(getSubTalentListing());
    yield put(editorModalToggle(false));
  } else {
    const { message } = response.data;
    yield put(updateSubTalentFailed(message));
  }
}

function* fetchSubTalentListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/category?subCategories=true`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(getSubTalentListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(getSubTalentListingFailed(message));
  }
}

export function* watchTalent() {
  yield takeEvery(GET_TALENT_LISTING, fetchTalentListing);
  yield takeEvery(GET_SUB_TALENT_LISTING, fetchSubTalentListing);
  yield takeEvery(UPDATE_TALENT, updateTalent);
  yield takeEvery(UPDATE_SUB_TALENT, updateSubTalent);
}

export default function* rootSaga() {
  yield all([fork(watchTalent)]);
}
