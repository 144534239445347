import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import * as actionType from './actionType';
import * as actions from './actions';

function* fetchInvitationListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/invitationRequests`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.getInvitationListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.getInvitationListingFailed(message));
  }
}

function* updateInvitationStatus({ payload }) {
  const { reqStatus, reqId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/invitationRequests/${reqId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: { requestStatus: reqStatus },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.updateInvitationSuccess(data));
    yield put(actions.getInvitationListing());
  } else {
    const { message } = response.data;
    yield put(actions.updateInvitationFailed(message));
  }
}

export function* watchInvitation() {
  yield takeEvery(actionType.GET_INVITATION_LISTING, fetchInvitationListing);
  yield takeEvery(actionType.UPDATE_INVITATION, updateInvitationStatus);
}

export default function* rootSaga() {
  yield all([fork(watchInvitation)]);
}
