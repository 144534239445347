export const GET_REPORT_LISTING = 'GET_REPORT_LISTING';
export const GET_REPORT_LISTING_SUCCESS = 'GET_REPORT_LISTING_SUCCESS';
export const GET_REPORT_LISTING_FAILED = 'GET_REPORT_LISTING_FAILED';

export const GET_REPORT_ACTION_LISTING = 'GET_REPORT_ACTION_LISTING';
export const GET_REPORT_ACTION_LISTING_SUCCESS =
  'GET_REPORT_ACTION_LISTING_SUCCESS';
export const GET_REPORT_ACTION_LISTING_FAILED =
  'GET_REPORT_ACTION_LISTING_FAILED';

export const REPORT_ACTION = 'REPORT_ACTION';
export const REPORT_ACTION_SUCCESS = 'REPORT_ACTION_SUCCESS';
export const REPORT_ACTION_FAILED = 'REPORT_ACTION_FAILED';

export const REPORT_EDITOR_MODAL_TOGGLE = 'REPORT_EDITOR_MODAL_TOGGLE';
