import {
  GET_REPORT_LISTING,
  GET_REPORT_LISTING_SUCCESS,
  GET_REPORT_LISTING_FAILED,
  REPORT_EDITOR_MODAL_TOGGLE,
  GET_REPORT_ACTION_LISTING,
  GET_REPORT_ACTION_LISTING_SUCCESS,
  GET_REPORT_ACTION_LISTING_FAILED,
  REPORT_ACTION_SUCCESS,
  REPORT_ACTION_FAILED,
} from './actionType';

const INIT_STATE = {
  reportListing: null,
  reportActions: null,
  loading: false,
  openModal: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORT_EDITOR_MODAL_TOGGLE:
      return { ...state, openModal: action.payload };
    case GET_REPORT_LISTING:
      return { ...state, loading: true, error: '' };
    case GET_REPORT_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        reportListing: action.payload,
        error: '',
      };
    case GET_REPORT_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        reportListing: null,
        error: action.payload.message,
      };
    case GET_REPORT_ACTION_LISTING:
      return { ...state, error: '' };
    case GET_REPORT_ACTION_LISTING_SUCCESS:
      return {
        ...state,
        reportActions: action.payload,
        error: '',
      };
    case GET_REPORT_ACTION_LISTING_FAILED:
      return {
        ...state,
        reportActions: null,
        error: action.payload.message,
      };
    case REPORT_ACTION_SUCCESS:
      return {
        ...state,
        error: '',
      };
    case REPORT_ACTION_FAILED:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
