/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { adminRoot, baseURL } from 'constants/defaultValues';
import menuItems from 'constants/menu';
import { setCurrentUser, setCurrentUserMenu } from 'helpers/Utils';
import { CallApi } from '../util';
import { LOGIN_USER, LOGOUT_USER } from './actionType';
import { loginUserSuccess, loginUserError } from './actions';

function* userLogin({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/adminUser/login`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: {
      email,
      password,
    },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data, token } = response.data;
    setCurrentUser({ ...data, role: 1 }, token);

    setCurrentUserMenu(menuItems);

    yield put(loginUserSuccess({ ...data, role: 1 }));
    history.push(adminRoot);
  } else {
    const { message } = response.data;
    yield put(loginUserError(message));
  }
}
function logout({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { history } = payload;

  const isStd = localStorage.getItem('studentLogin');
  if (isStd) {
    history.push('/user/authenticate');
  }
  setCurrentUser();
  setCurrentUserMenu();
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, userLogin);
  yield takeEvery(LOGOUT_USER, logout);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser)]);
}
