import * as actionType from './actionType';

const INIT_STATE = {
  postListing: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionType.GET_POST_LISTING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case actionType.GET_POST_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        postListing: [...state.postListing, ...action.payload],
        error: '',
      };
    case actionType.GET_POST_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        postListing: null,
        error: action.payload.message,
      };
    case actionType.ACTIVE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.ACTIVE_POST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case actionType.INACTIVE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.INACTIVE_POST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
