import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import {
  GET_REPORT_ACTION_LISTING,
  GET_REPORT_LISTING,
  REPORT_ACTION,
} from './actionType';
import * as actions from './actions';

function* fetchReportListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/reports`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.getReportListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.getReportListingFailed(message));
  }
}

function* fetchReportActionListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/reportActions`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.getReportActionListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.getReportActionListingFailed(message));
  }
}

function* reportAction({ payload }) {
  const { actionId, reportId } = payload;
  const apiOptions = {
    endpoint: `${baseURL}/api/reports/${reportId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'PUT',
    data: { reportAction: actionId },
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.reportActionSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.reportActionFailed(message));
  }
}

export function* watchReports() {
  yield takeEvery(GET_REPORT_LISTING, fetchReportListing);
  yield takeEvery(GET_REPORT_ACTION_LISTING, fetchReportActionListing);
  yield takeEvery(REPORT_ACTION, reportAction);
}

export default function* rootSaga() {
  yield all([fork(watchReports)]);
}
