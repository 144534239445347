export const GET_USER_LISTING = 'GET_USER_LISTING';
export const GET_USER_LISTING_SUCCESS = 'GET_USER_LISTING_SUCCESS';
export const GET_USER_LISTING_FAILED = 'GET_USER_LISTING_FAILED';

export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILED = 'DEACTIVATE_USER_FAILED';

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILED = 'ACTIVATE_USER_FAILED';

export const CREATE_AMBASSADOR = 'CREATE_AMBASSADOR';
export const CREATE_AMBASSADOR_SUCCESS = 'CREATE_AMBASSADOR_SUCCESS';
export const CREATE_AMBASSADOR_FAILED = 'CREATE_AMBASSADOR_FAILED';

export const REMOVE_AMBASSADOR = 'REMOVE_AMBASSADOR';
export const REMOVE_AMBASSADOR_SUCCESS = 'REMOVE_AMBASSADOR_SUCCESS';
export const REMOVE_AMBASSADOR_FAILED = 'REMOVE_AMBASSADOR_FAILED';
