import * as actionType from './actionType';

export const getInvitationListing = () => ({
  type: actionType.GET_INVITATION_LISTING,
});
export const getInvitationListingSuccess = (listing) => ({
  type: actionType.GET_INVITATION_LISTING_SUCCESS,
  payload: listing,
});
export const getInvitationListingFailed = (message) => ({
  type: actionType.GET_INVITATION_LISTING_FAILED,
  payload: { message },
});

export const updateInvitation = (reqStatus, reqId) => ({
  type: actionType.UPDATE_INVITATION,
  payload: { reqStatus, reqId },
});
export const updateInvitationSuccess = () => ({
  type: actionType.UPDATE_INVITATION_SUCCESS,
});
export const updateInvitationFailed = (message) => ({
  type: actionType.UPDATE_INVITATION_FAILED,
  payload: { message },
});
