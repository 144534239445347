import * as actionType from './actionType';

export const getTalentListing = () => ({
  type: actionType.GET_TALENT_LISTING,
});
export const getTalentListingSuccess = (listing) => ({
  type: actionType.GET_TALENT_LISTING_SUCCESS,
  payload: listing,
});
export const getTalentListingFailed = (message) => ({
  type: actionType.GET_TALENT_LISTING_FAILED,
  payload: { message },
});

export const addTalent = (payload) => ({
  type: actionType.GET_TALENT_LISTING,
  payload,
});
export const addTalentSuccess = () => ({
  type: actionType.GET_TALENT_LISTING_SUCCESS,
});
export const addTalentFailed = (message) => ({
  type: actionType.GET_TALENT_LISTING_FAILED,
  payload: { message },
});

export const updateTalent = (payload) => ({
  type: actionType.UPDATE_TALENT,
  payload,
});
export const updateTalentSuccess = () => ({
  type: actionType.UPDATE_TALENT_SUCCESS,
});
export const updateTalentFailed = (message) => ({
  type: actionType.UPDATE_TALENT_FAILED,
  payload: { message },
});

export const getSubTalentListing = () => ({
  type: actionType.GET_SUB_TALENT_LISTING,
});
export const getSubTalentListingSuccess = (listing) => ({
  type: actionType.GET_SUB_TALENT_LISTING_SUCCESS,
  payload: listing,
});
export const getSubTalentListingFailed = (message) => ({
  type: actionType.GET_SUB_TALENT_LISTING_FAILED,
  payload: { message },
});

export const addSubTalent = (payload) => ({
  type: actionType.ADD_SUB_TALENT,
  payload,
});
export const addSubTalentSuccess = () => ({
  type: actionType.ADD_SUB_TALENT_SUCCESS,
});
export const addSubTalentFailed = (message) => ({
  type: actionType.ADD_SUB_TALENT_FAILED,
  payload: { message },
});

export const updateSubTalent = (payload) => ({
  type: actionType.UPDATE_SUB_TALENT,
  payload,
});
export const updateSubTalentSuccess = () => ({
  type: actionType.UPDATE_SUB_TALENT_SUCCESS,
});
export const updateSubTalentFailed = (message) => ({
  type: actionType.UPDATE_SUB_TALENT_FAILED,
  payload: { message },
});

export const editorModalToggle = (toggle) => ({
  type: actionType.EDITOR_MODAL_TOGGLE,
  payload: toggle,
});
