import * as actionType from './actionType';

export const getReportListing = () => ({
  type: actionType.GET_REPORT_LISTING,
});
export const getReportListingSuccess = (listing) => ({
  type: actionType.GET_REPORT_LISTING_SUCCESS,
  payload: listing,
});
export const getReportListingFailed = (message) => ({
  type: actionType.GET_REPORT_LISTING_FAILED,
  payload: { message },
});

export const getReportActionListing = () => ({
  type: actionType.GET_REPORT_ACTION_LISTING,
});
export const getReportActionListingSuccess = (listing) => ({
  type: actionType.GET_REPORT_ACTION_LISTING_SUCCESS,
  payload: listing,
});
export const getReportActionListingFailed = (message) => ({
  type: actionType.GET_REPORT_ACTION_LISTING_FAILED,
  payload: { message },
});

export const reportAction = (data) => ({
  type: actionType.REPORT_ACTION,
  payload: data,
});
export const reportActionSuccess = () => ({
  type: actionType.REPORT_ACTION_SUCCESS,
});
export const reportActionFailed = (message) => ({
  type: actionType.REPORT_ACTION_FAILED,
  payload: { message },
});

export const editorModalToggle = (toggle) => ({
  type: actionType.REPORT_EDITOR_MODAL_TOGGLE,
  payload: toggle,
});
