import * as actionType from './actionType';

const INIT_STATE = {
  talentListing: null,
  subTalentListing: null,
  openModal: false,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionType.EDITOR_MODAL_TOGGLE:
      return { ...state, openModal: action.payload };
    case actionType.GET_TALENT_LISTING:
      return { ...state, loading: true, error: '' };
    case actionType.GET_TALENT_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        talentListing: action.payload,
        error: '',
      };
    case actionType.GET_TALENT_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        talentListing: null,
        error: action.payload.message,
      };
    case actionType.GET_SUB_TALENT_LISTING:
      return { ...state, loading: true, error: '' };
    case actionType.GET_SUB_TALENT_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        subTalentListing: action.payload,
        error: '',
      };
    case actionType.GET_SUB_TALENT_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        subTalentListing: null,
        error: action.payload.message,
      };
    case actionType.ADD_TALENT_SUCCESS:
      return {
        ...state,
        loading: false,
        openModal: false,
        error: '',
      };
    case actionType.ADD_TALENT_FAILED:
      return {
        ...state,
        loading: false,
        openModal: false,
        error: action.payload.message,
      };
    case actionType.UPDATE_TALENT_SUCCESS:
      return {
        ...state,
        loading: false,
        openModal: false,
        error: '',
      };
    case actionType.UPDATE_TALENT_FAILED:
      return {
        ...state,
        loading: false,
        openModal: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
