/* eslint-disable camelcase */
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
  contentBaseURL,
  folderPaths,
  alt_youtube_img_thumbnail_path,
  yt_hres_default_img_path,
  defaultPath,
  placeholderVideo,
  placeholderProfile,
} from 'constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};
export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};
export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};
export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};
export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('tmwUser') != null
        ? JSON.parse(localStorage.getItem('tmwUser'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user, token) => {
  try {
    if (user) {
      localStorage.setItem('tmwUser', JSON.stringify(user));
      localStorage.setItem('tmwAuthToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('tmwUser');
      localStorage.removeItem('tmwAuthToken');
      localStorage.removeItem('ExternalLogin');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const setCurrentUserMenu = (menu) => {
  try {
    if (menu) {
      localStorage.setItem('tmwUserMenu', JSON.stringify(menu));
    } else {
      localStorage.removeItem('tmwUserMenu');
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentUserMenu -> error',
      error
    );
  }
};

export const getCurrentUserMenu = () => {
  let menu = null;
  try {
    menu =
      localStorage.getItem('tmwUserMenu') != null
        ? JSON.parse(localStorage.getItem('tmwUserMenu'))
        : null;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js  : getCurrentUserMenu -> error',
      error
    );
    menu = null;
  }
  return menu;
};

export const getAuthToken = () => {
  let token = null;
  try {
    token =
      localStorage.getItem('tmwAuthToken') != null
        ? JSON.parse(localStorage.getItem('tmwAuthToken'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getAuthToken -> error', error);
    token = null;
  }
  return token;
};

export const setAuthToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('tmwAuthToken', JSON.stringify(token));
    } else {
      localStorage.removeItem('tmwAuthToken');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setAuthToken -> error', error);
  }
};

export const getProfilePicture = (picture = null) => {
  if (picture === '' || !picture) {
    const img_path = `${defaultPath}/${placeholderProfile}`;
    return img_path;
  }
  return `${defaultPath}/${picture}`;
};

export const getContentThumbnailURL = (type, userName, content) => {
  switch (type) {
    case '63778f613e6ea7415d76345e': // Post Type Images
      if (content?.images[0]?.includes('http')) return content?.videoImageUrl;
      return `${contentBaseURL}/${userName}/${folderPaths.photos}/${content?.images[0]}`;
    case '63778f703e6ea7415d763461': {
      // Post Type Video
      if (content?.videoType === 'youtube' && content?.videoImageUrl === null) {
        // eslint-disable-next-line no-use-before-define
        return youtubeThumbnailURL(content?.videoUrl);
      }
      if (content?.videoImageUrl?.includes('http'))
        return content?.videoImageUrl;
      return `${contentBaseURL}/${userName}/${folderPaths.video_thumbnail}/${content?.videoImageUrl}`;
    }
    case 'photo': {
      if (content === undefined || content === '') {
        const profile_path = getProfilePicture();
        return profile_path;
      }
      // if (content.includes('http')) return content;
      return `${contentBaseURL}/${userName}/${folderPaths.profile_photos}/${content}`;
    }
    default: // Post Type Audio
      if (content?.audioImageUrl?.includes('http'))
        return content?.audioImageUrl;
      return `${contentBaseURL}/${userName}/${folderPaths.audio_thumbnail}/${content?.audioImageUrl}`;
  }
};

const youtubeThumbnailURL = (yt_url) => {
  let yt_params;
  let yt_id;
  if (yt_url.includes('youtu.be')) {
    yt_params = yt_url.split('youtu.be/');
    yt_id = yt_params.length && yt_params[1].split('?')[0];
  } else {
    yt_params = yt_url.split('=');
    yt_id = yt_params.length && yt_params[1];
  }
  const yt_img_url = `${alt_youtube_img_thumbnail_path}${yt_id}${yt_hres_default_img_path}`;
  return yt_img_url;
};

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (num > 1000000) {
    return `${(num / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
  }
  if (num < 999) {
    return num; // if value < 1000, nothing to do
  }
  return 0;
};

export const getVideoThumbnail = (video) => {
  if (video === '' || !video) return `${defaultPath}/${placeholderVideo}`;

  return `${defaultPath}/${video}`;
};
