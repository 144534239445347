import * as actionType from './actionType';

const INIT_STATE = {
  invitationListing: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionType.GET_INVITATION_LISTING:
      return { ...state, loading: true, error: '' };
    case actionType.GET_INVITATION_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        invitationListing: action.payload,
        error: '',
      };
    case actionType.GET_INVITATION_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        invitationListing: null,
        error: action.payload.message,
      };
    case actionType.UPDATE_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.UPDATE_INVITATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
