import * as actionType from './actionType';

export const getPostListing = (page = 1) => ({
  type: actionType.GET_POST_LISTING,
  payload: { page },
});
export const getPostListingSuccess = (listing) => ({
  type: actionType.GET_POST_LISTING_SUCCESS,
  payload: listing,
});
export const getPostListingFailed = (message) => ({
  type: actionType.GET_POST_LISTING_FAILED,
  payload: { message },
});

export const activePost = (postId) => ({
  type: actionType.ACTIVE_POST,
  payload: postId,
});
export const activePostSuccess = () => ({
  type: actionType.ACTIVE_POST_SUCCESS,
});
export const activePostFailed = (message) => ({
  type: actionType.ACTIVE_POST_FAILED,
  payload: { message },
});

export const inactivePost = (postId) => ({
  type: actionType.INACTIVE_POST,
  payload: postId,
});
export const inactivePostSuccess = () => ({
  type: actionType.INACTIVE_POST_SUCCESS,
});
export const inactivePostFailed = (message) => ({
  type: actionType.INACTIVE_POST_FAILED,
  payload: { message },
});
