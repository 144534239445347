import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi, showToastMessage } from '../util';
import * as actionType from './actionType';
import * as actions from './actions';

function* processNewsletter({ payload }) {
  console.log(payload);
  const apiOptions = {
    endpoint: `${baseURL}/api/notification/sendNewsletterEmail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
    data: payload,
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.processNewsletterSuccess(data));
    showToastMessage(status, 'Sent Successfully');
  } else {
    const { message } = response.data;
    yield put(actions.processNewsletterFailed(message));
    showToastMessage(status, 'Something went wrong');
  }
}

export function* watchInvitation() {
  yield takeEvery(actionType.PROCESS_NEWSLETTER_EMAILS, processNewsletter);
}

export default function* rootSaga() {
  yield all([fork(watchInvitation)]);
}
