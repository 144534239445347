import * as actionType from './actionType';

const INIT_STATE = {
  userListing: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionType.GET_USER_LISTING:
      return { ...state, loading: true, error: '' };
    case actionType.GET_USER_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        userListing: action.payload,
        error: '',
      };
    case actionType.GET_USER_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        userListing: null,
        error: action.payload.message,
      };
    case actionType.CREATE_AMBASSADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.CREATE_AMBASSADOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case actionType.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.DEACTIVATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case actionType.REMOVE_AMBASSADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.REMOVE_AMBASSADOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case actionType.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.ACTIVATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
