/* eslint-disable no-unused-vars */
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { baseURL } from 'constants/defaultValues';
import { getAuthToken } from 'helpers/Utils';
import { CallApi } from '../util';
import * as actionType from './actionType';
import * as actions from './actions';

function* fetchUserListing() {
  const apiOptions = {
    endpoint: `${baseURL}/api/users?noOfPosts=true`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'GET',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.getUserListingSuccess(data));
  } else {
    const { message } = response.data;
    yield put(actions.getUserListingFailed(message));
  }
}

function* createAmbassador({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/users/addAmbassador/${payload}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.createAmbassadorSuccess(data));
    yield put(actions.getUserListing());
  } else {
    const { message } = response.data;
    yield put(actions.createAmbassadorFailed(message));
  }
}

function* removeAmbassador({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/users/removeAmbassador/${payload}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.removeAmbassadorSuccess(data));
    yield put(actions.getUserListing());
  } else {
    const { message } = response.data;
    yield put(actions.removeAmbassadorFailed(message));
  }
}

function* deactivateUser({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/users/deactivate/${payload}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.deactivateUserSuccess(data));
    yield put(actions.getUserListing());
  } else {
    const { message } = response.data;
    yield put(actions.deactivateUserFailed(message));
  }
}

function* activateUser({ payload }) {
  const apiOptions = {
    endpoint: `${baseURL}/api/users/activate/${payload}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    method: 'POST',
  };
  const apiResponse = yield call(CallApi, apiOptions);
  const { response } = apiResponse;

  const { status } = response.data;
  if (status) {
    const { data } = response.data;
    yield put(actions.activateUserSuccess(data));
    yield put(actions.getUserListing());
  } else {
    const { message } = response.data;
    yield put(actions.activateUserFailed(message));
  }
}

export function* watchUsers() {
  yield takeEvery(actionType.GET_USER_LISTING, fetchUserListing);
  yield takeEvery(actionType.CREATE_AMBASSADOR, createAmbassador);
  yield takeEvery(actionType.REMOVE_AMBASSADOR, removeAmbassador);
  yield takeEvery(actionType.DEACTIVATE_USER, deactivateUser);
  yield takeEvery(actionType.ACTIVATE_USER, activateUser);
}

export default function* rootSaga() {
  yield all([fork(watchUsers)]);
}
