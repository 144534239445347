/* eslint-disable camelcase */
export const UserRole = {
  Admin: 0,
  Editor: 1,
};

// Base URL For API

// export const baseURL = 'http://localhost:5002';
export const baseURL = 'https://tmwapi.prime-logics.com';

export const defaultURL = 'https://tmw.prime-logics.com';

export const defaultPath = 'https://tmwapi.prime-logics.com';
export const contentBaseURL = 'http://content.talentmyway.com';
export const folderPaths = {
  audio_thumbnail: 'audios_thumbnail',
  audio: 'audios',
  video: 'videos',
  profile_photos: 'profile_photos',
  photos: 'photos',
  video_thumbnail: 'videos_thumbnail',
  profile: 'profile_photos',
  cover_photo: 'cover_photos',
};

export const alt_youtube_img_thumbnail_path = 'https://i.ytimg.com/vi/';
export const yt_hres_default_img_path = `/hqdefault.jpg`;
export const placeholderProfile = `uploads/profile/image/imagePlaceholder.jpg`;
export const placeholderVideo = `uploads/video_thumbnails/video_thumbnail.jpg`;

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const adminRoot = '/app';
export const appRoute = '/app/tmw';
export const searchPath = `${adminRoot}/#`;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;

export const Days = [
  {
    label: 'Sunday',
    value: 0,
    key: 0,
  },
  {
    label: 'Monday',
    value: 1,
    key: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
    key: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
    key: 3,
  },
  {
    label: 'Thursday',
    value: 4,
    key: 4,
  },
  {
    label: 'Friday',
    value: 5,
    key: 5,
  },
  {
    label: 'Saturday',
    value: 6,
    key: 6,
  },
];

export const DefaultOptions = {
  label: 'All',
  key: 0,
  value: 'All',
};

export const TypeOptions = [
  {
    label: 'Full Course (All Topics)',
    key: 1,
    value: 'Yes',
  },
  {
    label: 'Only Compulsory Topics',
    key: 0,
    value: 'No',
  },
];

export const RequiredOptions = [
  {
    label: 'Compulsory',
    key: 1,
    value: 'Yes',
  },
  {
    label: 'Optional',
    key: 0,
    value: 'No',
  },
];

export const StatusOptions = [
  {
    label: 'Not Started',
    key: 1,
    value: 'Not Started',
  },
  {
    label: 'In Progress',
    key: 3,
    value: 'In Progress',
  },
  {
    label: 'Skip',
    key: 4,
    value: 'Skip',
  },
  {
    label: 'Completed',
    key: 2,
    value: 'Completed',
  },
];
