import * as actionType from './actionType';

const INIT_STATE = {
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionType.PROCESS_NEWSLETTER_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case actionType.PROCESS_NEWSLETTER_EMAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
